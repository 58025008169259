window.VuriaUtil = {
    matchHeightElements: {},
    matchHeightClass: 'match-height',

    elementExists: function (element) {
        if (element.indexOf('[') != -1 || element.indexOf(']') != -1) {
            return !!document.querySelectorAll(element)[0];
        } else if (element.charAt(0) === '#') {
            return !!document.getElementById(element.substring(1));
        } else if (element.charAt(0) === '.') {
            return !!document.getElementsByClassName(element.substring(1))[0];
        } else {
            return !!document.querySelectorAll(element)[0];
        }
    },


    matchHeight: function (element) {
        var _this = VuriaUtil;
        if (!element.classList.contains(_this.matchHeightClass)) {
            return;
        }
        var parent = element.parentNode;
        var elements = [];
        var maxHeight = 0;
        var nodesFound = false;
        var tag = element.tagName;
        var key = (element.dataset.matchHeightId ? element.dataset.matchHeightId : parseInt(Math.random() * 1000));

        if (this.matchHeightElements[key]) {
            if (Array.prototype.slice.call(this.matchHeightElements[key]).indexOf(element) > -1) {
                return false;
            }
        }

        if (!parent && !parent.querySelectorAll(tag + '.' + _this.matchHeightClass)) return false;

        while (!nodesFound) {
            if (!parent) break;

            elements = Array.prototype.slice.call(parent.querySelectorAll(tag + '.' + _this.matchHeightClass));
            elements = _this.getMatchingDepth(element, elements);

            if (elements.length <= 1 && elements.indexOf(element) > -1) {
                parent = parent.parentNode;
            } else if (parent.tagName.toLowerCase() === 'body') {
                break;
            } else {
                nodesFound = true;
            }
        }

        if (nodesFound) {
            if (_this.matchHeightElements[key] === undefined) {
                _this.matchHeightElements[key] = elements;
            } else {
                if (_this.matchHeightElements[key].indexOf(element) > -1) {
                    while (_this.matchHeightElements[key] !== undefined) {
                        key += Math.ceil(Math.random() * 1000);
                    }
                    _this.matchHeightElements[key] = elements;
                }
            }

            elements.forEach(function (element) {
                if (element.offsetHeight > maxHeight && document.body.contains(element)) {
                    maxHeight = element.offsetHeight;
                }
            })

            elements.forEach(function (element) {
                element.style.height = maxHeight + 'px';
                element.dataset.matchHeightId = key;
            })
        }
    },

    getMatchingDepth: function (element, elements) {
        var _this = this;
        var newElements = [];
        var elements = Array.prototype.slice.call(elements);
        var comparerDepth = element.getDepth();

        elements.forEach(function (element) {
            if (comparerDepth === element.getDepth()) {
                newElements.push(element);
            }
        })

        return newElements;
    },

    matchHeightUpdate: function () {
        var _this = this;
        if (Object.keys(this.matchHeightElements).length) {
            for (var key in _this.matchHeightElements) {
                var maxHeight = 0;
                var elements = _this.matchHeightElements[key];
                var total = elements.length;
                var count = 0;

                elements.forEach(function (element) {
                    element.style.height = null;
                    if (element.offsetHeight > maxHeight) {
                        maxHeight = element.offsetHeight;
                    }

                    if (!document.body.contains(element)) {
                        count++;
                    }
                })

                if (count === total) {
                    delete _this.matchHeightElements[key];
                    return;
                }

                elements.forEach(function (element) {
                    element.style.height = maxHeight + 'px';
                })
            }
        }
    },

    displayAsDollars: function(amount) {
        var amountStr = amount.toString();
        if (amountStr.slice(-3) === '.00') {
            amountStr = amountStr.slice(0, -3);
        }
        return '$' + amountStr;
    },
}


Element.prototype.serializeData = function () {
    if (this.tagName.toLowerCase() !== 'form') return

    var formElements = this.elements;
    var formParams = {};
    var i = 0;
    var elem = null;
    for (i = 0; i < formElements.length; i += 1) {
        elem = formElements[i];
        switch (elem.type) {
            case 'submit':
                break;
            case 'radio':
                if (elem.checked) {
                    formParams[elem.name] = elem.value;
                }
                break;
            case 'checkbox':
                if (elem.checked) {
                    formParams[elem.name] = setOrPush(formParams[elem.name], elem.value);
                }
                break;
            default:
                formParams[elem.name] = setOrPush(formParams[elem.name], elem.value);
        }
    }
    return formParams;
}


function jsonPost(url, data, callback, async) {
    var request = new XMLHttpRequest();
    request.open('POST', url, async);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.onload = function () {
        if (callback) {
            callback(request);
        }
    };

    data = Object.keys(data).map(
        function (k) {
            return k + '=' + encodeURIComponent(data[k])
        }
    ).join('&');

    request.send(data);
}

function setOrPush(target, val) {
    var result = val;
    if (target) {
        result = [target];
        result.push(val);
    }
    return result;
}

Number.prototype.formatMoney = function (decimals, thousands) {
    decimals = (decimals !== null ? decimals : 2);
    thousands = (thousands !== null ? thousands : 4);
    var regex = '\d(?=(\d{' + decimals + '})+' + (thousands > 0 ? '\.' : '$') + ')';
    return '$' + this.toFixed(decimals).replace(new RegExp(regex, 'g'), '$&,');
}

String.prototype.formatMoney = function (decimals, thousands) {
    return parseFloat(this).formatMoney(decimals, thousands);
}


Element.prototype.getDepth = function () {
    var depth = 0;
    var parent = this.parentNode;

    while (parent) {
        parent = parent.parentNode;
        depth++;
    }

    return depth;
}


Element.prototype.getParentByClass = function(value) {
    if (this === null || value === '') return;
    var parent = this.parentNode;
    var classList = parent.classList;

    while (parent.tagName.toLowerCase() !== 'html') {
        var found = false;
        Array.prototype.slice.call(classList).forEach(function (className) {
            if (className === value) {
                found = true;
            }
        })
        if (found) break;
        parent = parent.parentNode;
        classList = parent.classList;
    }

    return parent;
}

Element.prototype.emptyNode = function(content) {
    if (!!this.innerHTML) {
        this.innerHTML = '';
    }

    if (content) {
        this.innerHTML = content;
    }
}

String.prototype.toNode = function () {
    var div = document.createElement('div');
    div.innerHTML = this.toString().trim();
    return div.firstChild;
};
