window.ReturningUserAlert = function (url) {
    var _this = this;
    this.getDocumentsUrl = url;
    this.retrievedFiles = false;

    this.dialog = bootbox.dialog({
        title: 'Welcome back!',
        message: "<p>It looks like you have been gone for a little while. Would you like to print/view your previous documents or re-register?</p>",
        size: 'large',
        className: 'returning-user',
        buttons: {
            noclose: {
                label: "Print Documents",
                className: 'btn-success',
                callback: function () {
                    _this.printDocuments();
                    return false;
                }
            },
            cancel: {
                label: "Re-Hire",
                className: 'btn-info'
            },
        }
    });
};

ReturningUserAlert.prototype.printDocuments = function () {
    if (this.retrievedFiles) return false;

    var _this = this;
    var modal = this.dialog.get(0);
    var button = modal.getElementsByClassName('btn-success')[0];
    button.disabled = true;

    $.get(
        _this.getDocumentsUrl,
        function (response) {
            if (response.success) {
                var footerClone = modal.getElementsByClassName('modal-footer')[0].cloneNode(true);
                footerClone.innerHTML = '';

                for (var key in response.data) {
                    var value = response.data[key];
                    var link = document.createElement('a');
                    link.classList.add('btn');
                    link.classList.add('btn-info');
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', value);
                    link.textContent = key;

                    footerClone.appendChild(link);

                    _this.retrievedFiles = true;
                }

                modal.getElementsByClassName('modal-content')[0].appendChild(footerClone);

            } else {
                bootbox.alert(response.message);
                button.disabled = false;
            }
        }, 'JSON')
};