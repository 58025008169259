///////////////////////////////////////
//////// Compensation Braintree Form
///////////////////////////////////////
var WestUsaBraintree = function (CompForm) {
    var _this = this;
    this.CompForm = CompForm;
    this.formData = {};
    this.errorMessages = {};
    this.errors = false;
    this.paid = false;
    this.form = document.getElementById('checkout-form');
    this.endPoint = this.form.dataset.method;
    this.braintreeFields = this.form.querySelector('#braintree-fields');
    this.formInputs = Array.prototype.slice.call(this.form.querySelectorAll('input'));
    this.formSelects = Array.prototype.slice.call(this.form.querySelectorAll('select'));

    this.braintree = {};
    this.braintree.client = null;
    this.braintree.token = document.getElementById('braintree-token').value;

    this.showForm();
}

WestUsaBraintree.prototype.showForm = function () {
    this.braintreeFields.style.display = 'block';
    VuriaUtil.matchHeightUpdate();

    this.formInputs.forEach(function (element) {
        element.classList.add('required');
    })

    this.formSelects.forEach(function (element) {
        element.classList.add('required');
    })
}

WestUsaBraintree.prototype.hideForm = function () {
    this.CompForm.form.querySelector('#comp-no-cost').style.display = 'block';
    this.braintreeFields.style.display = 'none';

    this.formInputs.forEach(function (element) {
        element.classList.remove('required');
    })

    this.formSelects.forEach(function (element) {
        element.classList.remove('required');
    })
}

WestUsaBraintree.prototype.disable = function () {
    var _this = this;
    this.hideForm();
    var cleanFields = ['card_number', 'cvv', 'expiration_date']

    this.formInputs.forEach(function (input) {
        if (input.type !== 'submit' && cleanFields.includes(input.name)) {
            input.value = '';
        }
    })

    this.formSelects.forEach(function (select) {
        select.options[0].selected = true;
    })

    if (this.braintree.client) {
        this.brainTree.client.teardown();
        this.braintree.client = null;
    }
    delete this;
}

WestUsaBraintree.prototype.validate = function (clearBraintreeErrors) {
    var _this = this;
    this.errors = false;
    this.serializeData();

    if (clearBraintreeErrors) {
        this.errorMessages = {};
    }

    for (var name in this.formData) {
        var value = _this.formData[name];
        var element = _this.form.querySelector('[name="' + name + '"]');
        if (element instanceof HTMLElement) {
            var parent = element.parentNode;

            if (!value) {
                parent.classList.add('form-error');
                _this.errors = true;
            } else {
                if (name === 'card_number' && value.length < 16) {
                    _this.errors = true;
                    parent.classList.add('form-error');
                    parent.querySelector('.error-message').innerHTML = 'Please enter a 16 digit card number.';
                } else {
                    parent.classList.remove('form-error');
                    if (parent.querySelector('.error-message')) {
                        parent.querySelector('.error-message').innerHTML = '';
                    }
                }
            }

            if (_this.errorMessages[name]) {
                _this.errors = true;
                if (parent.querySelector('.error-message')) {
                    parent.querySelector('.error-message').textContent = _this.errorMessages[name];
                }
            }
        }
    }

    if (!this.errors && !this.CompForm.form.querySelector('#braintree-nonce').value && !this.paid) {
        this.createClient();
    } else{
        VuriaUtil.matchHeightUpdate();
    }
}

WestUsaBraintree.prototype.serializeData = function () {
    var _this = this;
    this.formData = {};
    var regex = /\s/g;

    this.formInputs.forEach(function (element) {
        _this.formData[element.name] = element.value.replace(regex, '');
    });

    this.formSelects.forEach(function (element) {
        _this.formData[element.name] = element.value.replace(regex, '');
    });
}

WestUsaBraintree.prototype.createClient = function () {
    var _this = this;
    braintree.client.create({
        authorization: _this.braintree.token,
    }, _this.buildBraintree.bind(_this))
}

WestUsaBraintree.prototype.buildBraintree = function (createError, clientInstance) {
    var _this = this;
    if (createError) {
        try {
            var errorData = {
                source: 'Braintree API',
                message: createError,
                token: document.getElementById('frontend-logging').value
            }
            jsonPost('/ajax/log-error', errorData);
        } catch (e) {}
        return false;
    }

    var regex = /\s+?/g;

    var data = {
        creditCard: {
            number: this.formData.card_number.replace(regex, ''),
            cvv: this.formData.cvv.replace(regex, ''),
            expirationDate: this.formData.expiration_date,
            billingAddress: {
                postalCode: this.formData.zip_code
            }
        }
    }

    clientInstance.request({
        endpoint: _this.endPoint,
        method: 'post',
        data: data,
    }, _this.handleBraintreeResponse.bind(_this))
}

WestUsaBraintree.prototype.handleBraintreeResponse = function (error, response) {
    var _this = this;
    if (error) {
        this.errorMessages = {};
        this.errors = true;

        if (error.details && error.details.originalError && error.details.originalError.fieldErrors) {
            var errors = error.details.originalError.fieldErrors[0].fieldErrors;
            _this.errorMessages = {};

            for (var idx in errors) {
                var data = errors[idx];

                switch (data.field) {
                    case 'number':
                        _this.errorMessages.card_number = data.message;
                        break;
                    case 'expirationMonth':
                        _this.errorMessages.expiration_date = data.message.replace('month ', '');
                        if (parseInt(data.code) === 81712) {
                            _this.errorMessages.expiration_date += '<br/ >Format: MM/YY';
                        }
                        break;
                    case 'billingAddress':
                        if (data.fieldErrors.length) {
                            _this.errorMessages.zip_code = '';
                            data.fieldErrors.forEach(function (error, idx) {
                                _this.errorMessages.zip_code += error.message
                            })
                        }
                        break;
                    default:
                        _this.errorMessages[data.field] = data.message;
                        break;
                }
            }
        }

        if (Object.keys(_this.errorMessages).length) {
            _this.validate();
        }
    } else {
        var nonce = response.creditCards[0].nonce;
        this.paid = true;
        this.CompForm.submit(nonce);
    }
}

WestUsaBraintree.prototype.post = function (event) {
    event.preventDefault();
    var _this = this;

    this.createClient();
}
