$(function () {
    $('.testimonial-slides').slick({
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: '29%',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: '20%'
                }
            },
            {
                breakpoint: 800,
                settings: {
                    centerPadding: '10%'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: '0%'
                }
            }
        ]
    });
    $('.image-slides').slick({
        arrows: false,
        slidesToShow: 1,
        infinite: true,
        fade: true,
        speed: 200,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
        swipe: false,
    });
    $('.image-slides').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $('.image-slides .slick-slide').css({"transform": "scale(1.1)", "-webkit-transform": "scale(1.1)"});
        $('.image-slides .slick-slide.slick-active').css({"transform": "scale(1)", "-webkit-transform": "scale(1)"});
    });
    $('.image-slides').on('init', function (event, slick) {
        $('.slick-slide.slick-active').css({"transform": "scale(1)", "-webkit-transform": "scale(1)"});
    });
    $('.link-slides').slick({
        slidesToShow: 2.27,
        prevArrow: $('.slider-prev'),
        nextArrow: $('.slider-next'),
        infinite: false,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1.5
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    // function sliderOffset () {
    //     $('.link-slides').on('setPosition', function (event, slick) {
    //         var values = $('.link-slides .slick-track').css('transform').match(/-?[\d\.]+/g);
    //         var x = parseInt(values[4]) + $(window).width() * .209;
    //         $('.link-slides .slick-track').css('transform', 'translate3d(' + x + 'px,0px,0px)');
    //     });
    // };
    function slickFix(element) {
        var slick = $(element).slick('getSlick');
        var slideCount = $(element).find('.slick-slide').length;
        var slidesToShow = $(element).slick('slickGetOption', 'slidesToShow');
        if (slideCount <= slidesToShow) {
            $(element).find('.slick-slide').removeClass('slick-center');
            $(element).find('.slick-slide').addClass('no-slick-slider');
        }
    };
    slickFix('.testimonial-slides');
    $('.placeholder img').click(function () {
        var $video = $(this).closest('.placeholder');
        $video.fadeOut(200, function () {
            var videoLink = $video.data('video-link');
            $video.closest('.footer-video-block').append("<iframe src='" + videoLink + "'></iframe>")
        });
    });
    $('.fancybox').fancybox({
        maxWidth: 800,
        maxHeight: 600,
        fitToView: false,
        width: '70%',
        height: '70%',
        autoSize: false,
        closeClick: false,
        openEffect: 'elastic',
        closeEffect: 'elastic'
    });
    $('.nav-toggle, button.header-close').click(function () {
        $('header').toggleClass('open');
    });
    var inputNumber = document.getElementById('input-number');
    var slider = document.getElementById('slider');
    if (slider) {
        var planData = window.westUsa.planData
        var possibleValues = planData.map(function(x) { return parseInt(x.monthly_cost); });
        var min = possibleValues[0];
        var max = possibleValues[possibleValues.length - 1];
        // This is for the slider on the homescreen.
        // Reminder: we mutate this range below. The most important thing to remember is that the range keys must be
        // percentages, with '%' at the end.
        var range = {
            min: min,
            max: max,
        };
        possibleValues.forEach(function(x, i) {
            var percent = Math.round(i / (possibleValues.length - 1) * 100);
            range[percent + '%'] = x;
        });
        noUiSlider.create(slider, {
            start: [min],
            behaviour: 'tap-drag', // Move handle on tap, bar is draggable
            range: range,
            format: wNumb({
                decimals: 0
            }),
            pips: { // Show a scale with the slider
                mode: 'values',
                values: possibleValues,
                density: 100,
                format: wNumb({
                    prefix: '$',
                    decimals: 0
                })
            },
            snap: true,
        });
        inputNumber.addEventListener("keypress", function(e) {
            if (e.which == '13') {
                e.preventDefault();
                e.target.blur();
            }
        });
        inputNumber.addEventListener("change", function() {
            if (this.value.length > 3) {
                this.value = this.value.slice(0, 3);
            } else if (this.value.length == 0) {
                this.value = 0;
            }
            var previous = parseInt(slider.noUiSlider.get(), 10);
            var previousIndex = possibleValues.indexOf(previous);
            var delta = this.value - previous;
            var newIndex = previousIndex + delta;
            if (Math.abs(delta) === 1 && possibleValues[newIndex] !== undefined) {
                slider.noUiSlider.set(possibleValues[newIndex]);
            } else {
                slider.noUiSlider.set(this.value);
            }
        });

        function findPlanByMonthlyCost(monthlyCost) {
            return planData.find(function(x) { return parseFloat(x.monthly_cost) === parseFloat(monthlyCost); });
        }

        function yearlyCap(monthlyCost) {
            var plan = findPlanByMonthlyCost(monthlyCost);
            if (plan.yearly_cap === null) {
                return "None";
            }
            return VuriaUtil.displayAsDollars(plan.yearly_cap);
        }

        function transactionFee(monthlyCost) {
            var plan = findPlanByMonthlyCost(monthlyCost);
            return VuriaUtil.displayAsDollars(plan.transaction_fee);
        }

        function updateNumbers() {
            slider.noUiSlider.on('change', function (values, handle) {
                var val = parseFloat(values[handle]);
                if (val < min) {
                    slider.noUiSlider.set(min);
                }
            });
            slider.noUiSlider.on('update', function (values, handle) {
                if (parseFloat(values[handle]) < min) {
                    return;
                }
                var monthlyCostInput = $('.monthly-cost input');
                $('.compensation-slider-block .slider-container input').val(values[handle]);
                monthlyCostInput.val(values[handle]);
                $('.yearly-cap .h1').text(yearlyCap(values[handle]));
                $('.transaction-fee .h1').text(transactionFee(values[handle]));
                if ($(window).width() >= 1400) {
                    switch (monthlyCostInput.val().length) {
                        case 3:
                            monthlyCostInput.width("10rem");
                            break;
                        case 2:
                            monthlyCostInput.width("7.3rem");
                            break;
                        case 1:
                            monthlyCostInput.width("5rem");
                            break;
                    }
                } else if ($(window).width() >= 700) {
                    switch (monthlyCostInput.val().length) {
                        case 3:
                            monthlyCostInput.width("8.6rem");
                            break;
                        case 2:
                            monthlyCostInput.width("6.3rem");
                            break;
                        case 1:
                            monthlyCostInput.width("4rem");
                            break;
                    }
                } else if ($(window).width() >= 0) {
                    switch (monthlyCostInput.val().length) {
                        case 3:
                            monthlyCostInput.width("6.1rem");
                            break;
                        case 2:
                            monthlyCostInput.width("4.9rem");
                            break;
                        case 1:
                            monthlyCostInput.width("3.5em");
                            break;
                    }
                }
            });
        }
        updateNumbers();
        $(window).resize(function () {
            updateNumbers();
            squareImage();
        });
    }
    function icaButtonController() {
        if ($('#akID\\[25\\]\\[value\\]').length) {
            $('#akID\\[25\\]\\[value\\]').next('label').click(function(e) {
                e.preventDefault();
            })
        }
    }
    icaButtonController();
    function enableFormButton() {
        if (!$('.compensation-form').hasClass('transaction')) {
            var $requiredInputs = $('.compensation-form .form-group input.required');
            var empty = false;
            $requiredInputs.each(function () {
                if ($(this).val() == '' || ($(this).attr('type') == 'checkbox' && !$(this).is(':checked'))) {
                    empty = true;
                }
            });
            $('.compensation-form .form-group select.required').each(function () {
                if (!$(this).val()) {
                    empty = true;
                }
            });
            if ($('#akID\\[59\\]\\[value\\]\\[0\\]').length) {
                if (!$('#akID\\[59\\]\\[value\\]\\[0\\]').is(':checked')) {
                    empty = true;
                }
            }
            if (empty) {
                // $('.next-btn.ccm-input-submit').attr('disabled', 'disabled');
            } else {
                $('.next-btn.ccm-input-submit').removeAttr('disabled');
            }
        }
    }
    enableFormButton();
    $('.compensation-form .form-group').on("click keypress", function () {
        enableFormButton();
    });
    //step six checkbox logic
    $('div.checkbox').click(function() {
        $('div.checkbox').removeClass('checked');
        $(this).addClass('checked');
        $('#branch').val($(this).data('branch'));
        enableFormButton();
    });

    $('h5.drop-down-button').click(function () {
        $(this).next('div.drop-down-radio').slideToggle('fast');
    });
    $('h5.drop-down-button').trigger('click');
    function formFunctions() {
        if ($('#akID\\[54\\]\\[value\\]\\[2\\]').is(':checked')) {
            $('.call-preference-drop-down .form-group.radio input').parent().siblings('.form-group.text').slideDown("fast");
        } else {
            $('.call-preference-drop-down .form-group.radio input').parent().siblings('.form-group.text').slideUp("fast");
        }

        if ($('#akID\\[56\\]\\[value\\]\\[4\\]').is(':checked')) {
            $('.board-membership-dropdown .form-group.radio input').parent().siblings('.form-group.text').slideDown("fast");
        } else {
            $('.board-membership-dropdown .form-group.radio input').parent().siblings('.form-group.text').slideUp("fast");
        }

        if ($('#akID\\[51\\]\\[value\\]\\[0\\]').is(':checked') || $('#akID\\[53\\]\\[value\\]\\[0\\]').is(':checked')) {
            $('#teamname').parent().slideDown("fast");
        } else {
            $('#teamname').parent().slideUp("fast");
        }
        if ($('#akID\\[55\\]\\[value\\]\\[0\\]').is(':checked')) {
            $('.board-membership-dropdown').slideDown("fast");
        } else {
            $('.board-membership-dropdown').slideUp("fast");
        }

        if ($('#akID\\[55\\]\\[value\\]\\[1\\]').is(':checked')) {
            $('.board-membership-links-dropdown').slideDown("fast");
        } else {
            $('.board-membership-links-dropdown').slideUp("fast");
        }

        if ($('#akID\\[58\\]\\[value\\]\\[0\\]').is(":checked")) {
            $('.document-transfer-block').slideDown("fast");
        } else {
            $('.document-transfer-block').slideUp("fast");
        }
    }
    formFunctions();
    $('.form-group.radio input').on('change', function () {
        formFunctions();
    });
    $('.login-btn').click(function() {
        var form = $(this).siblings('.boot-box-form').html();
        bootbox.dialog({
            title: 'Login',
            message: form,
            buttons: {
                submit: {
                    label: "Login",
                    callback: function () {
                        $(this).find('form').submit();
                    }
                }
            }
        })
    });
    $('.help-btn').click(function () {
        var modal = $(this).siblings('.boot-box-help').html();
        bootbox.dialog({
            title: '',
            message: modal
        });
    });
    $('label[for="akID[25][value]"] .sprite').click(function(e) {
        e.preventDefault;
        var modal = $('div.ica-modal').html();
        bootbox.dialog({
            title: '',
            message: modal
        }).off("shown.bs.modal").init(function() {
            $('.modal-dialog').addClass('ica-modal');
        });
    });
    $('html').on('submit', 'form.plan-form', function(e) {
        e.preventDefault();

        var $form = $(this);
        var path = $form.attr('action');
        var data = $form.serializeArray();

        var errors = false;
        $.each(data, function(key, value){
            if (value['value'] == '') {
                errors = true;;
            }
        })

        if (errors) {
            $(document).find('.bootbox-body .plan-form .form-errors').html('Please fill in all fields')
            return false;
        }

        $.post(path, data, function (r) {
            var r = $.parseJSON(r);
            if (!r.success) {
                $form.find('div.form-errors').html(r.errors);
            } else {
                //close bootbox and check that Plan is signed
                $('button.bootbox-close-button').trigger('click');
                $('input[name="confirm"]').val(1);
                $('a.plan-link').addClass('signed');
                $('a.plan-link').html("Plan Signed");
                $('html').trigger('form-validate');
            }
        });
    });
    $('.login-btn[data-login=failed]').trigger('click');
    if (window.location.hash && $('.email-form').attr('id') == window.location.hash.replace('#','')) {
        $('.nav-toggle').trigger('click');
    }
    $('.compensation-slider-block .callout-boxes > div').click(function () {
        var $tooltip = $(this).find('.tool-tip');
        if ($('.tool-tip').hasClass('active')) {
            $('.tool-tip').removeClass('active');
        }
        if (!$tooltip.hasClass('active')) {
            $tooltip.addClass('active');
        }
    });
    $('.tool-tip i').click(function (e) {
        e.stopPropagation();
        var $tooltip = $(this).closest('.tool-tip');
        if ($tooltip.hasClass('active')) {
            $tooltip.removeClass('active');
        }
    });
    function squareImage () {
        $('img.square').each(function() {
            $(this).height($(this).width());
        });
    }
    squareImage();
    $(window).resize(function () {
        squareImage();
    });
    // $(document).ready(function () {
    //     $('.select2').select2({
    //         placeholder: '--',
    //         minimumResultsForSearch: -1,
    //     })
    // })
});